<template>
    <div class="loginStyle">
        <div class="loginContainer">
            <el-form :model="loginForm" ref="loginForm"  class="loginForm" :rules="rules">
                <img class="logoImg" src="../assets/images/logo.png" alt="">
                <p class="loginTitle">互联网信息采集系统</p>
                <el-form-item prop="username">
                    <div style="display: flex;align-items: center">
                        <i class="el-icon-user"></i>
                        <el-input  class="elinput"
                                size="normal"
                                type="text"
                                v-model="loginForm.username"
                                auto-complete="off"
                                placeholder="请输入用户名"
                                   @keydown.enter.native="submitLogin('loginForm')"
                        ></el-input>
                    </div>
                </el-form-item>

                <el-form-item prop="password">
                    <div style="display: flex;align-items: center">
                        <i class="el-icon-lock"></i>
                        <el-input
                                class="elinput"
                                size="normal"
                                type="password"
                                v-model="loginForm.password"
                                auto-complete="off"
                                placeholder="请输入密码"
                                @keydown.enter.native="submitLogin('loginForm')"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item align="center">
                    <el-button
                            size="normal"
                            type="primary"
                            style="width: 100%; height: 60px;"

                            @click="submitLogin('loginForm')"
                    >登录</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
    import qs from 'qs'
    import ty from "../utils/ty";
    export default {
        name: "Login",
        data() {
            return {
                loading: false,
                vcUrl: '',
                loginForm: {},
                rules: {
                    username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}],

                }
            }
        },
        methods: {

            submitLogin(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/login?' + qs.stringify(this.loginForm)).then(res => {
                            console.log('qqq',res)
                            const jwt = res.msg;
                            // 将jwt存储到应用store中
                            this.$store.commit("SET_TOKEN", jwt)
                            this.$router.push('/index').catch(() =>{})

                        }).catch(error => {

                        })
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .loginStyle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../assets/images/login-bg.jpg');
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    .loginContainer {
        width: 87.5%;
        height: 87%;
        background-image: url('../assets/images/login1.jpg');
    }
    .loginForm{
        margin-left: 900px;
        margin-top: 210px;
        width: 400px;
    }
    .loginTitle {
        margin: 15px auto 20px auto;
        text-align: center;
        color: #303033;
    }
    .logoImg{
        width: 72px;
        height: 72px;
        margin: 0 auto;
        display: block;
    }
    /* 利用穿透，设置input边框隐藏 */
    .elinput>>>.el-input__inner {
        border: 0;
    }
    .elinput{
        margin-left: 20px;
    }
    .loginRemember {
        text-align: left;
        margin: 0px 0px 15px 0px;
    }

</style>
